<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.two_factor_authentication_activation_title') }}</h1>
      </header>

      <div class="mb-4">
        <p>
          {{ $t('message.two_factor_authentication_activation_introduction_1') }} <a href="https://fr.wikipedia.org/wiki/Double_authentification" target="_blank">{{ $t('message.two_factor_authentication_activation_introduction_link') }}</a>
          {{ $t('message.two_factor_authentication_activation_introduction_2')}}
          <br><br>
          {{ $t('message.two_factor_authentication_activation_introduction_3')}}<br>
          {{ $t('message.two_factor_authentication_activation_introduction_4')}}<br>
          {{ $t('message.two_factor_authentication_activation_introduction_5')}}
        </p>
      </div>
      <div id="reset_password_form">
        <v-form
          ref="form"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <div class="mdl-card__supporting-text">
            <p v-if="googleAuthenticatorEnabled === true">
              {{ $t('message.two_factor_authentication_activated')}}
            </p>
            <p v-if="googleAuthenticatorEnabled === false">
              {{ $t('message.two_factor_authentication_deactivated')}}
            </p>
          </div>

          <v-row>
            <v-col cols="12" sm="3">
              <v-switch :label="googleAuthenticatorEnabled ? $t('message.to_enable') : $t('message.deactivated')" hide-details v-model="googleAuthenticatorEnabled" v-on:change="handleQrCode()"></v-switch>
            </v-col>
            <v-col cols="6" v-if="googleAuthenticatorEnabled" v-show="activateForm">
              <v-text-field :label="$t('message.token') + ' *'" v-model="token"></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" v-if="googleAuthenticatorEnabled" v-show="activateForm">
              <v-btn color="primary" @click="putUser">{{ $t('message.to_enable') }}</v-btn>
            </v-col>
          </v-row>

          <div class="mt-2 text-justify" v-if="googleQrCode">
            <span>{{ $t('message.two_factor_authentication_activation_scan_1') }}</span>
            <br/>
            <span>{{ $t('message.two_factor_authentication_activation_scan_2') }}</span>
          </div>
          <div class="mt-2" v-if="googleQrCode">
            <img class="qr-code" :src="googleQrCode">
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'TwoFactorQrCode',
  data: () => ({
    successMessage: null,
    errors: [],
    loading: false,
    googleAuthenticatorEnabled: null,
    googleQrCode: null,
    activateForm: false,
    token: null
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    this.getUser()
    document.title = config.title + ' - ' + this.$t('message.two_factor_authentication_title')
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    }
  },
  methods: {
    getUser () {
      const user = this.$store.getters.getUser

      if (!user) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/users/' + user.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data

            if (user) {
              this.googleAuthenticatorEnabled = user.googleAuthenticatorEnabled

              if (this.googleAuthenticatorEnabled) {
                this.getQrCode('generate')
              }
            }
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    putUser () {
      const user = this.$store.getters.getUser

      const data = {
        googleAuthenticatorEnabled: this.googleAuthenticatorEnabled,
        token: this.token
      }

      if (!this.googleAuthenticatorEnabled) {
        data.googleAuthenticatorSecret = null
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/users/' + user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const user = response.data

            if (user) {
              localStorage.setItem('user', JSON.stringify(user))
              this.activateForm = false

              this.successMessage = this.$t('message.two_factor_authentication_updated')
              this.errors = []
            }
          }

          setTimeout(() => {
            this.loading = false
          }, 500)
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    },
    handleQrCode () {
      if (this.googleAuthenticatorEnabled) {
        this.activateForm = true
        this.getQrCode('create')
      } else {
        this.activateForm = false
        this.googleQrCode = null

        this.putUser()
      }
    },
    getQrCode (type) {
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/qr-code/google', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            type: type
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            const reader = new FileReader()

            reader.addEventListener('loadend', () => {
              this.googleQrCode = reader.result
            })

            reader.readAsDataURL(blob)
            this.loading = false
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    },
  }
}
</script>
